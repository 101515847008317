import Layout from 'components/Layout'
import Accelerator from 'components/states/ohio/Accelerator'
import DigitalSolutionsAccess from 'components/states/ohio/DigitalSolutionsAccess'
import { graphql, useStaticQuery } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'

export default function Ohio() {
	const data = useStaticQuery<ToolkitQuery>(query)

	const { wideReading, toolkits } = data

	return (
		<Layout showHeader={false} showFooter={false}>
			<Accelerator gradeLevel="6-12" level="secondary" wideReading={wideReading} toolkits={toolkits} />
			<DigitalSolutionsAccess gradeLevel="6-12" />
		</Layout>
	)
}

export interface AcceleratorBasketQueryEdge {
	edges: { node: { title: string; gatsbyImageData: IGatsbyImageData } }[]
}

export interface AcceleratorToolkitQueryEdge {
	edges: { node: { name: string; images: { gatsbyImageData: IGatsbyImageData }[] } }[]
}

interface ToolkitQuery {
	wideReading: AcceleratorBasketQueryEdge
	toolkits: AcceleratorToolkitQueryEdge
}

const query = graphql`
	query {
		wideReading: allContentfulAsset(
			filter: {
				title: {
					in: [
						"1R ARC Accelerator | Secondary Hook Book Basket"
						"2R ARC Accelerator | Secondary Hook Book Basket"
						"Wt ARC Accelerator | Secondary Hook Book Basket"
						"Bk ARC Accelerator | Secondary Hook Book Basket"
						"Or ARC Accelerator | Secondary Hook Book Basket"
						"Pu ARC Accelerator | Secondary Hook Book Basket"
					]
				}
			}
		) {
			edges {
				node {
					title
					gatsbyImageData
				}
			}
		}
		toolkits: allContentfulProduct(
			filter: {
				name: {
					in: [
						"IRLA Toolkit for Secondary Grades 1R"
						"IRLA Toolkit for Secondary Grades 2R"
						"IRLA Toolkit for Secondary Grades Wt"
						"IRLA Toolkit for Secondary Grades Bk"
						"IRLA Toolkit Or"
						"IRLA Toolkit Pu"
					]
				}
			}
		) {
			edges {
				node {
					name
					images {
						gatsbyImageData
					}
				}
			}
		}
	}
`
